const theme = {
  animation: {
    transition: "all 500ms ease",
  },
  button: {
    radius: "4px",
    shadow: "",
  },
  colors: {
    primary: "#fff01f",
    secondary: "#000",
    heading: "#392D40",
    black: "#201C22",
    gray: "#5A535E",
    lightGray: "#8A828F",
    white: "#f5f5f5",
  },
  spacing: {
    xxs: "0.5rem",
    xs: "1rem",
    sm: "1.5rem",
    md: "2.5rem",
    lg: "5rem",
    xl: "10rem",
    xxl: "15rem",
  },
}

export default theme
